import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

const AUTOPLAY_TIMER = 5000

export interface Props {
  claim?: string
  images?: ImageProps[]
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  images,
  variant = 'default',
}: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState([0, 0])
  const [pause, setPause] = useState(false)
  const timer = useRef<any>()

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: images.length,
    loop: true,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    move(s) {
      const opacities = s.details().positions.map((slide) => slide.portion)
      setOpacities(opacities)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('mouseover', () => {
        setPause(true)
      })
      sliderRef.current.addEventListener('mouseout', () => {
        setPause(false)
      })
    }

    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, pause, slider, sliderRef])

  return (
    <Container variant={variant}>
      <Slider ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide
            key={index}
            className={currentSlide === index ? 'active' : undefined}
            style={{
              opacity: opacities[index],
            }}
          >
            <Image {...item} />
          </Slide>
        ))}
      </Slider>
      {images.length > 1 ? (
        <Arrows row>
          <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || slider.next()}
          />
        </Arrows>
      ) : null}
      {claim ? <Claim>{claim}</Claim> : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 33%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.3)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 55.5vh;
        `
    }
  }}
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
  &.active {
    img {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    transform: scale(1.1);
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
`

const Claim = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 5rem;
  position: absolute;
  top: 50%;
  right: 1.9375rem;
  left: 1.9375rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;
  &:before,
  &:after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    transform: translateY(0.3125rem) rotate(45deg);
  }
  &:before {
    margin-right: 1.25rem;
  }
  &:after {
    margin-left: 1.25rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }

  @media (max-width: 767px) {
    &:before,
    &:after {
      display: none;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
