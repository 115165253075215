import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({ direction = 'L', onClick }: Props) {
  return (
    <Container dial={5} onClick={onClick}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 4.9375rem;
  height: 4.9375rem;
  background: ${rgba(theme.colors.variants.neutralLight1, 0.2)};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight1, 0.5)};
  }

  svg {
    width: auto;
    height: 4.125rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 0.6;
  }
`
